@import "../../../Assets/Styles/Variables.scss";

.home-container {
	width: 100%;
	padding-top: 100px;
	.home-wrapper {
		max-width: $max-screen-width;
		min-width: $min-screen-width;
		width: 100%;
		padding-top: 40px;
		margin: auto;
		.home-banner-box {
			.banner-top {
				width: 100%;
				display: flex;
				justify-content: space-between;
				padding-bottom: 42px;
				max-width: $max-screen-width;
				margin: auto;
				flex-direction: row;
				align-items: flex-start;
				.top-left-box {
					font-family: "Montserrat";
					font-size: 25px;
					font-weight: 500;
					line-height: 1.88;
					letter-spacing: 0.5px;
					text-align: left;
					color: #333;
				}
				.top-right-box {
					padding-top: 10px;
					font-family: "NotoSansKR";
					font-weight: 300;
					font-size: 13px;
					line-height: 1.87;
					text-align: right;
					color: #333;
				}
			}
			.banner-center {
				.center-banner-box {
					width: 100%;
					img {
						width: 100%;
					}
				}
			}
			.banner-bottom {
				display: flex;
				padding: 35px 0 45px;
				justify-content: space-between;
				.bottom-left-box {
					font-family: "Montserrat";
					font-size: 16px;
					font-weight: 500;
					letter-spacing: 1px;
					line-height: 1.88;
					color: #333;
				}
				.bottom-right-box {
					font-family: "NotoSansKR";
					font-size: 13px;
					font-weight: 300;
					line-height: 1.85;
					text-align: right;
					color: #333;
				}
			}
		}

		.home-project-box {
			.project-box {
				display: flex;
				padding-bottom: 50px;
				padding-top: 50px;
				.left-box {
					flex: 1 1;
					.top-left-title {
						font-family: "NotoSansKR";
						font-size: 16px;
						line-height: 1.88;
						font-weight: 500;
						color: #333;
						text-align: left;
						padding-bottom: 55px;
					}
					.top-left-description {
						font-size: 13px;
						font-weight: 300;
						line-height: 1.85;
						text-align: left;
						color: #333;
						font-family: "NotoSansKR";
					}
				}
				.right-box {
					flex: 1 1;
					width: 50%;
					height: 50%;
					img {
						-webkit-user-drag: none;
						-webkit-user-select: none;
						-moz-user-select: none;
						-ms-use-select: none;
						-ms-user-select: none;
						user-select: none;
						width: 100%;
					}
				}
			}
		}
		.home-about-box {
			.about-title {
				padding: 60px 0;
				text-align: center;
				span {
					font-family: "Montserrat";
					font-size: 25px;
					line-height: 1.36;
					font-weight: 400;
					color: #333;
				}
			}
			.about-list {
				display: flex;
				flex-wrap: wrap;
				justify-content: space-between;
				.list-box-container {
					.list-box-wrapper {
						display: flex;
						max-width: 480px;
						width: 100%;
						border-top: 1px solid #333;
						padding: 25px 0 35px;
						.list-box-title {
							flex: 1 1;
							font-family: "NotoSansKR";
							font-size: 14px;
							font-weight: 500;
							line-height: 1.43;
							text-align: left;
							color: #333;
							padding-top: 3px;
						}
						.list-box-description {
							flex: 1.4 1;
							font-size: 12px;
							font-weight: 300;
							line-height: 2;
							text-align: left;
							color: #333;
							font-family: "NotoSansKR";
						}
					}
					&:nth-last-child(1) {
						border-bottom: 1px solid #333;
					}
					&:nth-last-child(2) {
						border-bottom: 1px solid #333;
					}
				}
			}
		}
		.home-bottom-box {
			max-width: 1024px;
			margin: auto;
			.title-box {
				padding-top: 115px;
				padding-bottom: 45px;
				.title {
					font-family: "Montserrat";
					font-weight: 400;
					font-size: 22px;
					line-height: 1.55;
					text-align: left;
					color: #333;
				}
			}
			.list-box {
				display: flex;
				.list-container {
					display: flex;
					flex-direction: column;
					max-width: 325px;
					width: 100%;
					margin: 10px;
					.list-img {
						display: flex;
						align-items: center;
						justify-content: center;
						margin-bottom: 40px;
						img {
							-webkit-user-drag: none;
							-webkit-user-select: none;
							-moz-user-select: none;
							-ms-use-select: none;
							-ms-user-select: none;
							user-select: none;
							width: 100%;
						}
					}
					.list-text-box {
						display: flex;
						height: 175px;
						padding: 17px 0 30px;
						border-top: 1px solid #333;
						border-bottom: 1px solid #333;
						.list-title {
							flex: 1 1;
							font-family: "Montserrat";
							font-size: 14px;
							font-weight: 500;
							line-height: 1.43;
							text-align: left;
							color: #333;
						}
						.list-description {
							white-space: pre-wrap;
							flex: 2 1;
							font-size: 11px;
							font-weight: 300;
							font-family: "NotoSansKR";
							line-height: 1.5;
							text-align: left;
							color: #333;
							letter-spacing: -0.4px;
						}
					}
				}
			}
		}
	}
}
