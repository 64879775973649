.bottom-banner-container {
	.bottom-banner-wrapper {
		width: 100%;
		.bottom-banner {
			margin-top: 80px;
			width: 100%;
			height: 250px;
			display: flex;
			background-color: #e3ddd8;
			.bottom-banner-box {
				display: flex;
				flex-direction: column;
				justify-content: center;
				align-items: flex-start;
				max-width: 1024px;
				width: 100%;
				margin: auto;
				.banner-title {
					padding-bottom: 20px;
					text-align: left;
					font-family: "Montserrat";
					font-weight: 500;
					font-size: 30px;
					line-height: 1;
				}
				.banner-description {
					padding-bottom: 55px;
					padding-left: 2px;
					text-align: left;
					font-family: "Montserrat";
					font-weight: 300;
					font-size: 20px;
					line-height: 1.5;
				}
				.banner-button {
					height: 14px;
					max-width: 96px;
					width: 100%;
					// border-bottom: solid 1px #333333;
					display: flex;
					align-items: center;
					a {
						display: flex;
					}
					.banner-button-img {
						display: flex;
						padding-right: 5px;
						transition: 0.3s; /*애니메이션 동작 실행 시간 정의*/
						transform: translate(0, 0);
					}
					.banner-button-text {
						display: flex;
						font-family: "Montserrat";
						font-weight: 500;
						font-size: 14px;
						span::after {
							content: "";
							position: absolute; /*부모 요소에는 position: relative를 주어야 함*/
							background-color: #333333;
							height: 1px;
							left: 0;
							width: 0; /*초기에 보이지 않도록*/
							bottom: 45%; /*a태그 아래에 위치*/
							transition: 0.3s; /*애니메이션 동작 실행 시간 정의*/
						}
						transition: 0.3s;
					}
					.banner-button-text {
						display: flex;
						position: relative;
					}
				}
				.banner-button:hover {
					.banner-button-text {
						transform: translate(-20px, 0);
						span:after {
							width: 100%;
						}
					}
					.banner-button-img {
						transform: rotate(360deg);
						transform: translate(70px, 0);
					}
				}
			}
		}
	}
}
