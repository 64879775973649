.data-list-container {
	width: 100%;
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	.data-list-wrapper {
		width: 100%;
		.list {
			width: 100%;
			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;
			.item-container {
				width: 32%;
				margin: 0 0 70px 0px;
				float: left;
				.item-wrapper {
					cursor: pointer;
					text-decoration: none;
					color: inherit;
					.img-box {
						display: flex;
						position: relative;
						img {
							-webkit-user-drag: none;
							-webkit-user-select: none;
							-moz-user-select: none;
							-ms-use-select: none;
							user-select: none;
							width: 100%;
							height: auto;
						}
					}
					.img-box:after {
						content: "";
						position: absolute;
						background-color: #333;
						opacity: 0;
						width: 100%;
						height: 100%;
						transition: all 0.3s;
					}
					.top-text-box {
						display: flex;
						justify-content: space-between;
						align-items: center;
						margin: 25px 0;
						.title {
							font-family: "Montserrat";
							font-weight: 500;
							font-size: 18px;
							line-height: 1;
							letter-spacing: normal;
							color: #444;
							display: inline-block;
							position: relative;
						}
						.title:after {
							content: "";
							position: absolute;
							left: 0;
							bottom: 50%;
							width: 0;
							height: 2px;
							background-color: #333;
							transition: all 0.3s;
						}
						.date {
							font-family: "Montserrat";
							font-weight: 300;
							font-size: 12px;
							line-height: 1;
							letter-spacing: normal;
							color: #999;
							border-bottom: 1px solid #999;
						}
					}
				}
				.bottom-text-box {
					.address {
						font-family: "Montserrat";
						font-weight: 300;
						font-size: 13px;
						line-height: 1.5;
						letter-spacing: normal;
						text-align: left;
						color: #999;
					}
				}
			}
			.item-container:hover {
				.img-box:after {
					background-color: #333;
					opacity: 0.3;
				}
				.top-text-box {
					.title:after {
						width: 100%;
					}
				}
			}
		}
	}
}
