@import "../../../Assets/Styles/Variables.scss";
.contact-container {
	width: 100%;
	padding-top: 100px;
	.contact-wrapper {
		max-width: $max-screen-width;
		min-width: $min-screen-width;
		width: 100%;
		margin: 0 auto 80px;
		text-align: center;
		.contact-top-box {
			width: 100%;
			margin: auto;
			display: flex;
			justify-content: space-between;
			align-items: flex-start;
			padding: 50px 0 80px;
			.contact-title {
				font-family: "Montserrat";
				font-size: 24.5px;
				font-weight: 500;
				line-height: 1;
				letter-spacing: 7.35px;
				text-align: left;
				color: #444;
			}
			.contact-description {
				font-family: "NotoSansKR";
				font-size: 13px;
				font-weight: 300;
				line-height: 1.81;
				letter-spacing: normal;
				text-align: right;
				color: #333;
			}
		}
		.contact-bottom-box {
			.contact-map-box {
				width: 100%;
				height: 300px;
			}
			.contact-text-box {
				.info-container {
					margin-top: 80px;
					text-align: end;
					.info-wrapper {
						font-family: "Montserrat";
						.title {
							color: #000;
							cursor: text;
							font-size: 20px;
							font-weight: 500;
							margin-top: 34px;
							margin-bottom: 8px;
							letter-spacing: 1px;
						}
						.email {
							position: relative;
							font-weight: 300;
							font-size: 14px;
							letter-spacing: 1px;
							span::after {
								content: "";
								position: absolute; /*부모 요소에는 position: relative를 주어야 함*/
								background-color: #333333;
								height: 1px;
								left: 0;
								width: 0; /*초기에 보이지 않도록*/
								bottom: 40%; /*a태그 아래에 위치*/
								transition: 0.3s; /*애니메이션 동작 실행 시간 정의*/
							}
						}
						.phone-number {
							font-size: 14px;
							font-weight: 100;
						}

						a > span:hover:after {
							width: 100%;
						}
					}
				}
			}
		}
	}
}
