@import "../../../Assets/Styles/Variables.scss";
.about-container {
	width: 100%;
	padding-top: 100px;
	.about-wrapper {
		max-width: $max-screen-width;
		min-width: $min-screen-width;
		width: 100%;

		margin: auto;
		.about-introduce-box {
			width: 100%;
			align-items: flex-start;
			margin: auto;
			display: flex;
			justify-content: space-between;
			padding-top: 45px;
			.introduce-title {
				font-family: "NotoSansKR";
				font-weight: 500;
				font-size: 25px;
				letter-spacing: 7.5px;
				line-height: 1.6;
				text-align: left;
				color: #333;
				flex: 1 1;
			}
			.introduce-description {
				display: flex;
				text-align: right;
				font-family: "NotoSansKR";
				font-weight: 300;
				font-size: 13px;
				line-height: 1.85;
				letter-spacing: normal;
				color: #333;
			}
		}
		.about-introduce-banner {
			.introduce-banner {
				max-width: 1024px;
				width: 100%;
				display: flex;
				flex-direction: column;
				margin: 85px auto 115px;
			}
		}
		.about-philosophy-container {
			.about-philosophy-wrapper {
				display: flex;
				flex-direction: column;
				max-width: 1024px;
				margin: auto;
				color: #0e1215;
				.list {
					height: 130px;
					border-bottom: 2px solid #a1a1a1;
					display: flex;
					flex-direction: row;
					align-items: center;
					justify-content: space-between;
					color: #333;
					white-space: pre-wrap;
					.list-title {
						font-family: "Montserrat";
						font-size: 25px;
						font-weight: 500;
						line-height: 1;
					}
					.list-description {
						font-family: "NotoSansKR";
						text-align: right;
						font-size: 13px;
						font-weight: 300;
						line-height: 1.85;
					}
				}
			}
		}
		.about-directional-container {
			margin-top: 90px;
			margin-bottom: 100px;
			.about-directional-wrapper {
				.title {
					display: flex;
					flex-direction: column;
					justify-content: center;
					padding: 90px 0;
					text-align: center;
					font-family: "NotoSansKR";
					font-weight: 400;
					font-size: 25px;
					color: #333;
				}
				.list-container {
					display: flex;
					flex-wrap: wrap;
					justify-content: space-between;
					.list-wrapper {
						display: flex;
						max-width: 480px;
						width: 100%;
						border-top: solid 1px #333333;
						padding: 25px 0 35px;
						.list-title {
							text-align: left;
							flex: 1;
							font-family: "NotoSansKR";
							font-weight: 500;
							font-size: 14px;
							line-height: 1.43;
							color: #333333;
						}
						.list-description {
							text-align: left;
							flex: 1.4;
							font-family: "NotoSansKR";
							font-weight: 300;
							font-size: 12px;
							line-height: 2.17;
							color: #333333;
						}
						&:nth-last-child(1) {
							border-bottom: 1px solid #333;
						}
						&:nth-last-child(2) {
							border-bottom: 1px solid #333;
						}
					}
				}
			}
		}
	}
}
