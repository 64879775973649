.sub-line-container {
	display: flex;
	border-top: solid 2px #333333;
	border-bottom: solid 1px #333333;
	align-items: center;
	height: 44px;
	.sub-line-wrapper {
		width: 100%;
		display: flex;
		justify-content: space-between;

		align-items: center;
		.sub-line-title {
			font-family: "Montserrat";
			font-size: 13px;
			font-weight: 500;
			letter-spacing: 1px;
			color: #333333;
		}
		a {
			display: flex;
			align-items: center;

			.sub-line-more {
				font-family: "Montserrat";
				font-size: 11px;
				text-align: right;
				letter-spacing: 1px;
				color: #333;
				span {
					position: relative;
				}
				span::after {
					content: "";
					position: absolute; /*부모 요소에는 position: relative를 주어야 함*/
					background-color: #333333;
					height: 1px;
					left: 0;
					width: 0; /*초기에 보이지 않도록*/
					bottom: 40%; /*a태그 아래에 위치*/
					transition: 0.3s; /*애니메이션 동작 실행 시간 정의*/
				}
			}
			.sub-line-icon {
				display: flex;
				justify-content: center;
				align-items: center;
				transition: all ease 0.3s;
			}
		}

		a:hover {
			.sub-line-more {
				span:after {
					width: 100%;
				}
			}
			.sub-line-icon {
				transform: rotate(180deg);
			}
		}
	}
}
