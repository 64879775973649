@import "../../../Assets/Styles/Variables.scss";

.data-dialog-container {
	position: fixed;
	width: 100%;
	height: 100vh;

	top: 0;
	right: 0;
	bottom: 0;
	left: 0;

	z-index: 100;
	background-color: rgba(0, 0, 0, 0.8);
	transform: 0.3s;

	.data-dialog-wrapper {
		display: flex;
		justify-content: center;

		.dialog-box {
			width: $max-screen-width;
			display: flex;
			z-index: 101;
			height: 100vh;
			background-color: #fff;
			flex-direction: column;
			position: relative;

			overflow: visible;
			.dialog-menu-box {
				display: flex;
				position: absolute;
				top: 130px;
				right: -80px;
				flex-direction: column;
				align-items: center;
				gap: 24px;
				.menu-btn {
					display: flex;
					cursor: pointer;

					justify-content: center;
					flex-direction: column;
					align-items: center;
					.icon-box {
						cursor: pointer;
						display: flex;
						flex-direction: column;
						align-items: center;
						justify-content: center;
						border-radius: 50%;
						width: 45px;
						height: 45px;
						border: none;
						text-align: center;
						background-color: #fff;
						img {
							width: 32px;
							height: 32px;
						}
					}
					span {
						cursor: pointer;
						position: relative;
						color: #fff;
						font-family: "Montserrat";
						font-weight: 300;
						font-size: 14px;
						line-height: 2;
						letter-spacing: 1px;
					}
					span:after {
						content: "";
						position: absolute; /*부모 요소에는 position: relative를 주어야 함*/
						background-color: #fff;
						height: 1px;
						left: 0;
						width: 0; /*초기에 보이지 않도록*/
						bottom: 50%; /*a태그 아래에 위치*/
						transition: 0.3s; /*애니메이션 동작 실행 시간 정의*/
					}
				}
				.menu-btn:hover {
					span:after {
						width: 100%;
					}
				}
			}
			.dialog-page {
				display: flex;
				overflow: scroll;
				justify-content: center;

				.header-box {
					position: fixed;
					display: flex;
					align-items: center;
					justify-content: space-between;
					border-bottom: 1px solid #333;
					flex-direction: row;
					background-color: #fff;
					width: $max-screen-width;
					.header {
						display: flex;
						height: 100px;
						width: 100%;
						margin-left: 50px;
						align-items: center;
						font-family: "Montserrat";
						font-weight: 500;
						font-size: 25px;
						line-height: 1;
						letter-spacing: 7.5px;
						text-align: left;
						color: #444;
					}
					.close-btn {
						cursor: pointer;
						margin-right: 30px;
						img {
							transition: all ease 0.3s;
						}
					}
					.close-btn:hover {
						img {
							transform: rotate(180deg);
						}
					}
				}
				.contants-box {
					margin-top: 100px;
					.contants-list-box {
						.contants-list-title {
							display: flex;
							margin-left: 50px;
							height: 100px;
							align-items: center;
							span {
								font-family: "Montserrat";
								font-weight: 500;
								font-size: 25px;
								line-height: 1;
								letter-spacing: 7.5px;
								text-align: left;
								color: #444;
							}
						}
						.contants-list-list {
							display: flex;
							flex-direction: column;
							width: 100%;

							.list-img {
								margin: 0px 30px 30px 30px;
								img {
									width: 100%;
								}
							}
							&:nth-last-child(1) {
								border-bottom: 1px solid #333;
							}
						}
					}
				}
			}
		}
	}
	::-webkit-scrollbar {
		display: none;
	}
}
