@import "../../../Assets/Styles/Variables.scss";
.consulting-container {
	width: 100%;
	padding-top: 100px;
	.consulting-wrapper {
		max-width: $max-screen-width;
		min-width: $min-screen-width;
		width: 100%;
		margin: auto;
		.consulting-intro-box {
			margin: auto;
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			align-items: flex-start;
			padding: 50px 0;
			.title {
				font-family: "Montserrat";
				font-weight: 500;
				font-size: 25px;
				line-height: 1;
				letter-spacing: 7.5px;
				text-align: left;
				color: #444;
			}
			.description {
				font-family: "NotoSansKR";
				font-weight: 300;
				font-size: 13px;
				line-height: 1.85;
				letter-spacing: normal;
				text-align: right;
				color: #333;
			}
		}
		.consulting-menu {
		}
		.consulting-data-list {
		}
		.contact-consulting-box {
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			.contact-icon {
				display: flex;
				flex-direction: column;
				align-items: center;
				justify-content: center;
				border-radius: 50%;
				width: 150px;
				height: 150px;

				text-align: center;
				margin-bottom: 16px;
				img {
					width: 70%;
					height: 70%;
				}
			}
			.consulting-text {
				display: flex;
				text-align: center;
				justify-content: center;
				flex-direction: column;
				line-height: 2;
				margin-bottom: 30px;
				span {
					font-family: "NotoSansKR";
					font-size: 24px;
					font-weight: 300;
				}
				a {
					font-size: 20px;
					font-weight: 300;
					span {
						position: relative;
						font-family: "Montserrat";
					}
				}
				a > span:after {
					content: "";
					position: absolute;
					left: 0;
					bottom: 50%;
					width: 0;
					height: 1px;
					background-color: #333;
					transition: all 0.3s;
				}
				a > span:hover::after {
					width: 100%;
				}
			}
		}
	}
}
