.app-root {
	width: 100%;
	.app-root-wrapper {
		width: 100%;
		.app-root-header {
			justify-content: center;
			width: 100%;
		}
		justify-content: center;
		width: 100%;
	}
}
