@import "../../../Assets/Styles/Variables.scss";
.project-container {
	width: 100%;
	padding-top: 100px;
	.project-wrapper {
		max-width: $max-screen-width;
		min-width: $min-screen-width;
		width: 100%;
		margin: auto;
		.project-intro-box {
			margin: auto;
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			align-items: flex-start;
			padding: 50px 0;
			.title {
				font-family: "Montserrat";
				font-weight: 500;
				font-size: 25px;
				line-height: 1;
				letter-spacing: 7.5px;
				text-align: left;
				color: #444;
			}
			.description {
				font-family: "NotoSansKR";
				font-weight: 300;
				font-size: 13px;
				line-height: 1.85;
				letter-spacing: normal;
				text-align: right;
				color: #333;
			}
		}
		.project-menu {
		}
		.project-data-list {
		}
	}
}
