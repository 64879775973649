//NotoSansKR 한글 글꼴
@font-face {
	font-family: "NotoSansKR";
	font-weight: 100;
	src: url("../Fonts/NotoSans_KR/NotoSansKR-Thin.otf");
}
@font-face {
	font-family: "NotoSansKR";
	font-weight: 300;
	src: url("../Fonts/NotoSans_KR/NotoSansKR-Light.otf");
}

@font-face {
	font-family: "NotoSansKR";
	font-weight: 400;
	src: url("../Fonts/NotoSans_KR/NotoSansKR-Regular.otf");
}
@font-face {
	font-family: "NotoSansKR";
	font-weight: 500;
	src: url("../Fonts/NotoSans_KR/NotoSansKR-Medium.otf");
}
@font-face {
	font-family: "NotoSansKR";
	font-weight: 700;
	src: url("../Fonts/NotoSans_KR/NotoSansKR-Bold.otf");
}
@font-face {
	font-family: "NotoSansKR";
	font-weight: 900;
	src: url("../Fonts/NotoSans_KR/NotoSansKR-Black.otf");
}

//Montserrat 영문 글꼴
@font-face {
	font-family: "Montserrat";
	font-weight: 100;
	src: url("../Fonts/Montserrat/Montserrat-Thin.ttf");
}
@font-face {
	font-family: "Montserrat";
	font-weight: 300;
	src: url("../Fonts/Montserrat/Montserrat-Light.ttf");
}
@font-face {
	font-family: "Montserrat";
	font-weight: 400;
	src: url("../Fonts/Montserrat/Montserrat-Regular.ttf");
}
@font-face {
	font-family: "Montserrat";
	font-weight: 500;
	src: url("../Fonts/Montserrat/Montserrat-Medium.ttf");
}

@font-face {
	font-family: "Montserrat";
	font-weight: 700;
	src: url("../Fonts/Montserrat/Montserrat-Bold.ttf");
}
@font-face {
	font-family: "Montserrat";
	font-weight: 900;
	src: url("../Fonts/Montserrat/Montserrat-Black.ttf");
}
