@import "../../../Assets/Styles/Variables.scss";

.header-container {
	min-width: $min-screen-width;
	display: flex;
	justify-content: center;
	border-bottom: solid 1px #ffffff;
	z-index: 99;
	position: fixed;
	width: 100%;
	background-color: #ffffff;
	border-bottom: solid 1px #333333;
	.header-wrapper {
		max-width: $max-screen-width;
		min-width: $min-screen-width;
		display: flex;
		justify-content: space-between;
		align-items: center;
		width: 100%;
		height: 100px;
		position: relative;
		.logo-img {
			max-width: 75px;
			display: flex;
			justify-content: center;
			align-items: center;
		}
		.menu {
			display: flex;
			justify-content: center;
			align-items: center;
			gap: 20px;
			font-size: 18px;
			.kr {
				font-family: "NotoSansKR";
				font-weight: 500;
				padding-bottom: 4px;
			}
			.en {
				font-family: "Montserrat";
				font-weight: 500;
				letter-spacing: 1px;
			}
			a {
				display: inline-block;
				color: #333;
				position: relative;
			}
			a:after {
				content: "";
				position: absolute;
				left: 0;
				bottom: 50%;
				width: 0;
				height: 2px;
				background-color: #333;
				transition: all 0.3s;
			}

			a:hover:after {
				width: 100%;
			}
			.active::after {
				width: 100%;
			}
		}
	}
}
