@import "../../../Assets/Styles/Variables.scss";

.footer-container {
	display: flex;
	padding: 0 30px;
	font-family: "Montserrat";
	justify-content: center;
	background-color: #fff;
	height: 324px;
	border-top: 1px solid;
	min-width: $min-screen-width;
	.footer-wrapper {
		display: flex;
		align-items: center;
		max-width: $max-screen-width;
		min-width: $min-screen-width;
		width: 100%;
		color: #333;
		.footer-left-box {
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			flex: 3 1;
			.top-box {
				max-width: 198px;
				display: flex;
				align-items: center;
				justify-content: space-between;
				.logo-img {
					max-width: 58px;
					-webkit-filter: brightness(0);
					filter: brightness(0);
					img {
						width: 100%;
						-webkit-user-drag: none;
						-webkit-user-select: none;
						-moz-user-select: none;
						-ms-use-select: none;
						-ms-user-select: none;
						user-select: none;
					}
				}
				.sns-links {
					display: flex;
					flex-direction: column;
					font-family: "Montserrat";
					font-weight: 500;
					letter-spacing: 1.1px;
					line-height: 2;
					font-size: 11px;

					span {
						display: inline-block;
						color: #333;
						position: relative;
					}
					a > span:after {
						content: "";
						position: absolute;
						left: 0;
						bottom: 50%;
						width: 0;
						height: 1px;
						background-color: #333;
						transition: all 0.3s;
					}
					a > span:hover:after {
						width: 100%;
					}
				}
			}
			.bottom-box {
				font-family: "Montserrat";
				font-weight: 400;
				margin-top: 45px;
				letter-spacing: 1px;
				font-size: 10px;
			}
		}
		.footer-center-box {
			.top-box {
				margin-bottom: 50px;
				span {
					font-family: "Montserrat";
					font-size: 11px;
					line-height: 2;
					letter-spacing: 1.1px;
					text-align: left;
					font-weight: 500;
				}
			}
			.bottom-box {
				.address-kr {
					font-family: "NotoSansKR";
					font-size: 11px;
					line-height: 2;
					letter-spacing: 1.1px;
					text-align: left;
					font-weight: 500;
				}
				.address-en {
					font-family: "Montserrat";
					font-size: 11px;
					line-height: 2;
					letter-spacing: 1.1px;
					text-align: left;
					font-weight: 500;
				}
			}
		}
		.footer-right-box {
			color: #333;
			font-size: 11px;
			line-height: 2;
			letter-spacing: 1.1px;
			text-align: right;
			font-weight: 500;
			display: flex;
			justify-content: space-between;
			.links {
				display: flex;
				flex-direction: column;
				flex-wrap: wrap;
				.link {
					a {
						.kr {
							font-family: "NotoSansKR";
						}
						.en {
							font-family: "Montserrat";
						}
					}
					a {
						display: inline-block;
						color: #333;
						position: relative;
					}
					a:after {
						content: "";
						position: absolute;
						left: 0;
						bottom: 50%;
						width: 0;
						height: 1px;
						background-color: #333;
						transition: all 0.3s;
					}
					a:hover:after {
						width: 100%;
					}
					.active::after {
						width: 100%;
					}
				}
			}
		}
	}
}
