.menu-container {
	width: 100%;

	text-align: center;
	.menu-wrapper {
		max-width: 1024px;
		display: flex;
		width: 100%;
		text-align: center;
		grid-gap: 10%;
		gap: 10%;
		justify-content: center;
		border-top: 2px solid #333;
		border-bottom: 1px solid #333;
		margin: 0 auto 34px;
		padding-top: 6px;
		padding-bottom: 6px;
		.radio-btn {
			position: relative;
			.menu-item {
				opacity: 0;
				visibility: hidden;
				position: absolute;
			}
			.menu-label {
				cursor: pointer;
				font-family: "Montserrat";
				font-size: 16px;
				color: #333;
				letter-spacing: 2.4px;
			}
			input[type="radio"]:checked + label:after {
				width: 100%;
			}
			.menu-label:after {
				content: "";
				position: absolute;
				left: 0;
				bottom: 50%;
				width: 0;
				height: 2px;
				background-color: #333;
				transition: all 0.3s;
			}
			.menu-label:hover::after {
				width: 100%;
			}
		}
	}
}
